import React from "react";
import DefaultLayout from "../layouts/DefaultLayout";
import { Typography } from "@cncxt/imodium-component-library";

const PageNotFound = (): JSX.Element => {
  return (
    <DefaultLayout>
      <Typography variant="h3">Page Not Found</Typography>
    </DefaultLayout>
  );
};

export default PageNotFound;
